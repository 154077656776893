import React, { useRef } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Tabs from "../../components/tabs/Tabs";
import TabLink from "../../components/tabs/TabLink";
import TabContent from "../../components/tabs/TabContent";
import { Link } from "gatsby";
import { Accordion, AccordionItem } from "react-light-accordion";
import "react-light-accordion/demo/css/index.css";

import Layout from "../../components/mschool/layout";
import SEO from "../../components/seo";
import TopBanner from "../../components/mschool/topbanner";
import Power from "../../components/mschool/power";
import SchedulePopup from "../../components/schedulepopup";
import ProInstructors from "../../components/mschool/proinstructors";
import useWindowSize from "../../hooks/useWindowSize";
import settings from "../../../settings";
import Track from "../../components/track";

// banner
const banner =
	`${settings.IMAGES_BASE_URL}/v2/images/M-driver/m-driver-banner.jpg`;
const bannerMobile =
	`${settings.IMAGES_BASE_URL}/v2/images/M-driver/m-driver-banner.jpg`;
// schools
const twodaymschool =
	`${settings.IMAGES_BASE_URL}/v2/images/class-thumbnails/two-day-m-class.jpg`;
const advancedmschool =
	`${settings.IMAGES_BASE_URL}/v2/images/class-thumbnails/advanced-m-school-banner%201.png`;
const reacelicenseschool =
	`${settings.IMAGES_BASE_URL}/v2/images/class-thumbnails/race-license-class.jpg`;
const onedaymschool =
	`${settings.IMAGES_BASE_URL}/v2/images/class-thumbnails/one-day-m-class.jpg`;
const m4gt4school =
	`${settings.IMAGES_BASE_URL}/v2/images/class-thumbnails/m4-gt4-class.jpg`;
const indyfullday =
	`${settings.IMAGES_BASE_URL}/v2/images/indy_images/full-day-indy-box.jpg`;

// learn
const drifting =
`${settings.IMAGES_BASE_URL}/v2/images/m3-wet-skid-pad.jpg`;
const learnline =
	`${settings.IMAGES_BASE_URL}/v2/images/one-day-M/learn_the_line.jpg`;
const buddy =
	`${settings.IMAGES_BASE_URL}/v2/images/buddy_up.jpg`;
const leadfollow = 
  `${settings.IMAGES_BASE_URL}/v2/images/advanced-m/lead-follow.jpg`;

const Agenda = () => {
  return (
    <SchedulePopup
      btnText="View M Driver’s Agenda"
    >
      <div className="two_days_schedule">
        <div className="mr_10">
          <div>
            <h6>M DRIVER'S PROGRAM  <br />
              - NORMAL SCHEDULE
            </h6>
            <small>Sample schedule subject to change</small>
          </div>
          <div className="schedule__item">
            <p className="schedule__item__time">8:00 am-8:30 am</p>
            <p className="schedule__item__event">
              Arrival, registration
            </p>
          </div>
          <div className="schedule__item">
            <p className="schedule__item__time">8:30 am-9:40 am</p>
            <p className="schedule__item__event">
              Class discussion
            </p>
          </div>

          <div className="schedule__item">
            <p className="schedule__item__time">9:40 am-12:20 pm</p>
            <p className="schedule__item__event">Drive time</p>
          </div>

          <div className="schedule__item">
            <p className="schedule__item__time">12:30 pm-1:00 pm</p>
            <p className="schedule__item__event">Lunch</p>
          </div>
          <div className="schedule__item">
            <p className="schedule__item__time">12:00 pm-1:00 pm</p>
            <p className="schedule__item__event">Lunch</p>
          </div>
          <div className="schedule__item">
            <p className="schedule__item__time">1:15 pm-4:10 pm</p>
            <p className="schedule__item__event">Drive time</p>
          </div>
          <div className="schedule__item">
            <p className="schedule__item__time">4:10 pm-4:30 pm</p>
            <p className="schedule__item__event">Closing</p>
          </div>
        </div>
        <div>
          <div>
            <h6>M DRIVER'S PROGRAM <br />- THERMAL SUMMER <br /> SCHEDULE</h6>
            <small>Summer hours for our California location only, <br /> valid
              from June 1- September 30.
            </small>
          </div>
          <div className="schedule__item">
            <p className="schedule__item__time">6:00 am-6:30 am</p>
            <p className="schedule__item__event">Arrival, registration</p>
          </div>
          <div className="schedule__item">
            <p className="schedule__item__time">6:30 am-7:30 am</p>
            <p className="schedule__item__event">Class discussion</p>
          </div>

          <div className="schedule__item">
            <p className="schedule__item__time">7:30 am-12:30 pm</p>
            <p className="schedule__item__event">Drive time</p>
          </div>

          <div className="schedule__item">
            <p className="schedule__item__time">12:30 pm-1:30 pm</p>
            <p className="schedule__item__event">Lunch</p>
          </div>
        </div>
      </div>
    </SchedulePopup>
  );
};

const MDriverPage = () => {
	const scrollToView = (ref) => {
		ref.current.scrollIntoView({
			behavior: "smooth",
		});
	};
	const { width } = useWindowSize();
	const isMobile = width < 1200;
	const isDesktop = width >= 1200;
	const learnRef = useRef(null);
	const loactionsRef = useRef(null);
	const powerRef = useRef(null);
	const instructorsRef = useRef(null);
	const classesRef = useRef(null);
	return (
		<Layout>
			<SEO title="BMW Performance Driving Program | M Driver's Package"
        description="This is M 101 - designed to help you learn the limits of BMW M cars while exploring your own. To owners of recently purchased M cars - welcome to the family, let’s get you up to speed."
        keywords="bmw m driver, bmw m school, m driving school, bmw m driving school, bmw m performance driving school"
      />
			<div className="mdriverspage">
				<TopBanner
					image={banner}
					mobileImage={bannerMobile}
					header="M Driver's Package"
					subheader="THE BASICS OF PERFORMANCE"
					pricing="From $1,550"
					text="This is M 101 - designed to help you learn the limits of BMW M cars while exploring your own. To owners of recently purchased M cars - welcome to the family, let’s get you up to speed."
					buttonText="Book in California"
					butttonURL="https://pds.eventsbmw.com/?location=Thermal%2C%20CA&brand=M&class=M_Driver%27s_Package"
					agendaPopup={<Agenda />}
				>
          <p>
            Please note the Summer hours for our Performance Center West location only. Classes in Thermal will begin at 6AM, June 1st through September 30th. Please check your confirmation email for details.
          </p>
        </TopBanner>
				<section className="links__tabs">
					<div>
						<ul>
							<li>
								<button
									onClick={() => {
										scrollToView(learnRef);
									}}
								>
									What you’ll learn
								</button>
							</li>
							<li>
								<button
									onClick={() => {
										scrollToView(loactionsRef);
									}}
								>
									Locations
								</button>
							</li>
							<li>
								<button
									onClick={() => {
										scrollToView(powerRef);
									}}
								>
									What you’ll drive
								</button>
							</li>
							<li>
								<button
									onClick={() => {
										scrollToView(instructorsRef);
									}}
								>
									Pro instructors
								</button>
							</li>
							<li>
								<button
									onClick={() => {
										scrollToView(classesRef);
									}}
								>
									Other classes
								</button>
							</li>
						</ul>
					</div>
				</section>
				<section className="learn" ref={learnRef}>
					<h2>WHAT YOU’LL LEARN</h2>
					{isMobile && (
						<Accordion atomic={true}>
							<AccordionItem title="Drifting">
								<LazyLoadImage effect="blur" src={drifting} alt="Drifting" />
								<div className="accordion-content">
									<h3>Slip, then grip</h3>
									<p>
										Take one M car. Add water, subtract grip. Feel the
										difference between under- and oversteer, and learn to
										control the back of the car with throttle. Get it right, and
										you’ll start to glide sideways through our polished concrete
										skidpad with ease.
									</p>
								</div>
							</AccordionItem>
							<AccordionItem title="Buddy Up">
								<LazyLoadImage effect="blur" src={buddy} alt="Buddy Up" />
								<div className="accordion-content">
									<h3>Ride Sharing</h3>
									<p>
										This program has you share an M car with another driver as
										you both learn the ins-and-outs of high-speed driving and
										control.
									</p>
								</div>
							</AccordionItem>
							<AccordionItem title="Learn the Line">
								<LazyLoadImage
									effect="blur"
									src={learnline}
									alt="Learn the Line"
								/>
								<div className="accordion-content">
									<h3>Technical Perfection</h3>
									<p>
										These tracks are set up to be short, with aggressive turns
										that require a balance bet ween throttle and brake. You Il
										learn this dance by looking as far ahead as possible and
										planning the proper drive lines through compromise corners.
									</p>
								</div>
							</AccordionItem>
							<AccordionItem title="Lead-Follow">
								<LazyLoadImage
									effect="blur"
									src={leadfollow}
									alt="Lead-Follow"
								/>
								<div className="accordion-content">
									<h3>Follow the leader</h3>
									<p>
										You go where you look, and here you’ll be looking at our
										lead instructor’s car as they guide you around the proper
										line at ever-increasing speeds.
									</p>
								</div>
							</AccordionItem>
						</Accordion>
					)}
					<div className="container">
						{isDesktop && (
							<Tabs>
								<div className="learn__images">
									<TabContent for="drifting" uniqueKey="1">
                      <img src={drifting} alt="Drifting" />
                    </TabContent>
                    <TabContent for="buddy-up" uniqueKey="2">
                      <img src={buddy} alt="Buddy Up" />
                    </TabContent>
                    <TabContent for="autocross" uniqueKey="3">
                      <img src={learnline} alt="Learn the Line" />
                    </TabContent>
                    <TabContent for="lead-follow" uniqueKey="4">
                      <img src={leadfollow} alt="Lead-Follow" />
                    </TabContent>
								</div>
								<ul className="learn__menu" role="tablist" aria-owns="drifting buddy-up autocross lead-follow" aria-busy="true">
									<li role="presentation">
										<TabLink id="drifting" to="drifting" role="tab" uniqueKey="5">Drifting</TabLink>
									</li>
									<li role="presentation">
										<TabLink id="buddy-up" to="buddy-up" role="tab" uniqueKey="6">Buddy Up</TabLink>
									</li>
									<li role="presentation">
										<TabLink id="autocross" to="autocross" role="tab" uniqueKey="7">Learn the Line</TabLink>
									</li>
									<li role="presentation">
										<TabLink id="lead-follow" to="lead-follow" role="tab" uniqueKey="8">Lead-Follow</TabLink>
									</li>
								</ul>
								<div className="learn__content">
									<TabContent for="drifting" uniqueKey="9">
										<h3>Slip, then grip</h3>
										<p>
											Take one M car. Add water, subtract grip. Feel the
											difference between under- and oversteer, and learn to
											control the back of the car with throttle. Get it right,
											and you’ll start to glide sideways through our polished
											concrete skidpad with ease.
										</p>
									</TabContent>
									<TabContent for="buddy-up" uniqueKey="10">
										<h3>Ride Sharing</h3>
										<p>
											This program has you share an M car with another driver as
											you both learn the ins-and-outs of high-speed driving and
											control.
										</p>
									</TabContent>
									<TabContent for="autocross" uniqueKey="11">
										<h3>Technical Perfection</h3>
										<p>
											These tracks are set up to be short, with aggressive turns
											that require a balance between throttle and brake. You'Il
											learn this dance by looking as far ahead as possible and
											planning the proper drive lines through compromise
											corners.
										</p>
									</TabContent>
									<TabContent for="lead-follow" uniqueKey="12">
										<h3>Follow the leader</h3>
										<p>
											You go where you look, and here you’ll be looking at our
											lead instructor’s car as they guide you around the proper
											line at ever-increasing speeds.
										</p>
									</TabContent>
								</div>
							</Tabs>
						)}
					</div>
				</section>
				<Track ref={loactionsRef} />
				<Power ref={powerRef} />
				<ProInstructors ref={instructorsRef} />
				<section className="other-schools" ref={classesRef}>
					<div className="container">
						<h2>YOU MAY ALSO LIKE</h2>
					</div>
					<div className="other-schools__content row">
						<div className="other-schools__item">
							<LazyLoadImage
								effect="blur"
								src={twodaymschool}
								alt="Two-Day M School"
							/>
							<h3>Two-Day M School</h3>
							<p>Focus on the details of true M Performance</p>
							<Link to="/mschool/twodaymschool">
								<button className="btn btn-bordered">Learn More</button>
							</Link>
						</div>
						<div className="other-schools__item">
							<LazyLoadImage
								effect="blur"
								src={advancedmschool}
								alt="Advanced M School"
							/>
							<h3>Advanced M School</h3>
							<p>The faint of heart need not apply.</p>
							<Link to="/mschool/advancedmschool">
								<button className="btn btn-bordered">Learn More</button>
							</Link>
						</div>
						<div className="other-schools__item">
							<LazyLoadImage
								effect="blur"
								src={reacelicenseschool}
								alt="Race License School"
							/>
							<h3>Race License School</h3>
							<p>Become a pro.</p>
							<Link to="/mschool/racelicenseschool">
								<button className="btn btn-bordered">Learn More</button>
							</Link>
						</div>
						<div className="other-schools__item">
							<LazyLoadImage
								effect="blur"
								src={onedaymschool}
								alt="One-Day M School"
							/>
							<h3>One-Day M School</h3>
							<p>The basics of performance are anything but basic.</p>
							<Link to="/mschool/onedaymschool">
								<button className="btn btn-bordered">Learn More</button>
							</Link>
						</div>
						<div className="other-schools__item">
							<LazyLoadImage
								effect="blur"
								src={m4gt4school}
								alt="M4 GT4 School"
							/>
							<h3>M4 GT4 School</h3>
							<p>Learn to drive the ultimate BMW M.</p>
							<Link to="/mschool/m4gt4">
								<button className="btn btn-bordered">Learn More</button>
							</Link>
						</div>
						<div className="other-schools__item">
							<LazyLoadImage
								effect="blur"
								src={indyfullday}
								alt="Full-Day M Experience at Indy"
							/>
							<h3>Full-Day M Experience at Indy</h3>
							<p>Full-on thrills on a hallowed track.</p>
							<Link to="/indy-fullday">
								<button className="btn btn-bordered">Learn More</button>
							</Link>
						</div>
					</div>
				</section>
			</div>
		</Layout>
	);
};

export default MDriverPage;
